import { UPDATE_USER_LOGIN_STATE } from '../actions/userActions';

const initialState = {
  isLogined: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_LOGIN_STATE:
      return { isLogined: action.isLogined };
    default:
      return state;
  }
}
