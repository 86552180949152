// # Redux
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

// # Reducers
import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import languageReducer from './languageReducer';
import userReducer from './userReducer';


const rootReducer = combineReducers({
  form: reduxFormReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  language: languageReducer,
  user: userReducer,
});

export default rootReducer;
