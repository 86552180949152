// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import axios from 'axios';
import {
  Area, AreaChart, ResponsiveContainer,
} from 'recharts';
import { Table } from 'reactstrap';

// # Redux
import { connect } from 'react-redux';

// # Components
import Panel from '../Panel';

// # Instruments
import { API_URL, DEMO } from '../../globalVariables';
import { getUniqueID } from '../../helpers';


class DashboardTopLoadedDevices extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();

    this.state = {
      topLoadedDevices: [],
    };
  }

  componentDidMount() {
    this.getTopLoadedDevicesInfo();
  }

  getTopLoadedDevicesInfo = async () => {
    if (!DEMO) {
      await axios.get(`${API_URL}/modules/top/5/loaded/orders/stat/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const { success, data } = res.data;

          if (success) {
            // # Remove this piece of sheet after server fix
            const sortedArray = [...data].sort((firstData, secondData) => {
              const placedCellNumbersOfFirstData = firstData.placed.reduce(
                (accumul, value) => ({ count: accumul.count + value.count }),
              ).count;

              const placedCellNumbersOfSecondData = secondData.placed.reduce(
                (accumul, value) => ({ count: accumul.count + value.count }),
              ).count;

              return (placedCellNumbersOfFirstData < placedCellNumbersOfSecondData) ? 1 : -1;
            });

            return this.setState({ topLoadedDevices: sortedArray });

            // return this.setState({ topLoadedDevices: data });
          }

          return null;
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  renderTopLoadedDevice = (device, index) => {
    const { dictionary } = this.props;
    let deviceLoadAvarage = 0;

    for (let i = 0; i < device.placed.length; i += 1) {
      deviceLoadAvarage += device.placed[i].count;
    }

    return (
      <tr key={getUniqueID()}>
        <td>{index + 1}</td>
        <td>{device.id}</td>
        <td>{device.address}</td>
        <td>{device.stacks}</td>
        <td>{device.cells}</td>
        {/* <td>{device.load_avarage}</td> */}
        <td>{(deviceLoadAvarage / 7).toFixed(2)}</td>
        <td className="dashboard__table-crypto-chart">
          <ResponsiveContainer height={36}>
            <AreaChart data={device.placed}>
              <Area
                name={dictionary.orders}
                type="monotone"
                dataKey="count"
                fill="#4ce1b6"
                stroke="#4ce1b6"
                fillOpacity={0.2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </td>
      </tr>
    );
  }

  render() {
    const { topLoadedDevices } = this.state;
    const { dictionary } = this.props;

    return (
      <Panel lg={12} title={dictionary.topLoadedDevices}>
        <Table responsive className="table--bordered dashboard__table-crypto">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>{dictionary.address}</th>
              <th>{dictionary.modules}</th>
              <th>{dictionary.cells}</th>
              <th>{dictionary.loadAvarage}</th>
              <th>{dictionary.sevenDaysChart}</th>
            </tr>
          </thead>
          <tbody>
            {topLoadedDevices.map((device, index) => this.renderTopLoadedDevice(device, index))}
          </tbody>
        </Table>
      </Panel>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(DashboardTopLoadedDevices);
