// Demo
export const DEMO = false;

// API URLs
// export const API_URL = 'http://192.168.200.143:8008/api/1.0.0/dashboard';
// export const API_URL = 'http://194.183.168.187:8008/api/1.0.0/dashboard';
export const API_URL = 'https://rozprod.omnic.solutions/api/dashboard';

// Google Maps
export const GOOGLE_MAPS_API_KEY = 'AIzaSyD84CRFR44xSC242F5rPodUZ3CqKbUlqMw';

// Languages
export const languages = ['ru', 'ua', 'en'];
export const defaultLanguage = 'ru';

// Chart/Legend default colors
export const defaultColors = ['#ff6384', '#b0e685', '#36a2eb', '#f7de74', '#3b3be8'];
