// # Core
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  objectOf, arrayOf, shape, string, number,
} from 'prop-types';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell,
} from 'recharts';

// # Redux
import { connect } from 'react-redux';

// # Components
import ArrowDownwardIcon from 'mdi-react/ArrowDownwardIcon';
import Panel from '../Panel';

// # Instruments
import { defaultColors } from '../../globalVariables';
import { getUniqueID } from '../../helpers';


const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={getUniqueID()}>
          <span style={{ backgroundColor: defaultColors[index] }} />
          <p>{entry.value}</p>
        </li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: arrayOf(shape({
    color: string,
    value: string,
  })).isRequired,
};

class NetworkItem extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
    className: string,
    networkData: shape({
      id: number.isRequired,
      name: string.isRequired,
      owner: string.isRequired,
      totalDevices: number.isRequired,
      devicesOnline: number.isRequired,
      devicesOffline: number.isRequired,
      devicesToRepair: number.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    className: 'theme-white',
  }

  render() {
    const {
      className,
      dictionary,
      networkData: {
        id,
        name,
        owner,
        totalDevices,
        devicesOnline,
        devicesOffline,
        devicesToRepair,
      },
    } = this.props;

    const toolTipItemStyleStyle = {
      color: (className === 'theme-dark') ? '#ffffff' : '#333333',
      fontSize: 13,
    };

    const pieData = [
      { name: dictionary.online, value: devicesOnline },
      { name: dictionary.repair, value: devicesOffline },
      { name: dictionary.offline, value: devicesToRepair },
    ];

    return (
      <Panel
        lg={6}
        xl={3}
        md={12}
        title={name}
        subhead={owner}
        key={getUniqueID()}
      >
        <div className="dashboard__visitors-chart">
          <p className="dashboard__visitors-chart-title">
            <span>{dictionary.totalDevicesInNetwork}</span>
          </p>
          <p className="dashboard__visitors-chart-number">{totalDevices}</p>
          <ResponsiveContainer className="dashboard__chart-pie" width="100%">
            <PieChart className="dashboard__chart-pie-container">
              <Tooltip itemStyle={toolTipItemStyleStyle} offset={-300} useTranslate3d={false} />
              <Pie
                data={pieData}
                dataKey="value"
                cy={110}
                innerRadius={70}
                outerRadius={100}
              >
                {
                  pieData.map((_, index) => <Cell key={getUniqueID()} fill={defaultColors[index]} stroke="none" />)
                }
              </Pie>
              <Legend
                layout="vertical"
                verticalAlign="bottom"
                content={renderLegend}
                wrapperStyle={{
                  position: 'relative', left: 0, bottom: 0, width: '100%',
                }}
              />
            </PieChart>
          </ResponsiveContainer>
          <Link className="dashboard__booking-reservations-link" to={`/network/${id}`}>
            { dictionary.readMore } <ArrowDownwardIcon className="dashboard__booking-reservations-link-icon" />
          </Link>
        </div>
      </Panel>
    );
  }
}


const mapStateToProps = (state) => {
  const { className } = state.theme;
  const { dictionary } = state.language;

  return {
    className,
    dictionary,
  };
};

export default connect(mapStateToProps)(NetworkItem);
