// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';

// # Instruments
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { API_URL, DEMO } from '../../globalVariables';

// # Fake data
import fakeData from './fakeData.json';

class DashboardDevicesOnline extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();
    this.state = fakeData;

    this.getTerminalsData = this.getTerminalsData.bind(this);
  }

  componentDidMount() {
    this.getTerminalsData();
  }

  getTerminalsData = async () => {
    if (!DEMO) {
      await axios.get(`${API_URL}/modules/online-offline/stat/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const {
            success,
            data: {
              total_modules: totalTerminals,
              total_modules_online: terminalsOnline,
              modules_online_ratio: terminalsOnlineRatio,
            },
          } = res.data;

          return (success) ? this.setState({ totalTerminals, terminalsOnline, terminalsOnlineRatio }) : '';
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  render() {
    const { totalTerminals, terminalsOnline, terminalsOnlineRatio } = this.state;
    const { dictionary } = this.props;

    return (
      <Col md={12} xl={4} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title dashboard__booking-total-title--green">
                {terminalsOnline}
              </h5>
              <TrendingUpIcon className="dashboard__trend-icon" />
            </div>
            <h5 className="dashboard__booking-total-description">{dictionary.devicesOnline}</h5>
            <div className="progress-wrap progress-wrap--small progress-wrap--lime-gradient progress-wrap--rounded">
              <p className="dashboard__booking-card-progress-label progress__label">
                {terminalsOnlineRatio}% {dictionary.of} {totalTerminals}
              </p>
              <Progress value={terminalsOnlineRatio} />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(DashboardDevicesOnline);
