// # Actions
import { UPDATE_LANGUAGE_DICTIONARY } from '../actions/languageActions';

// # Instruments
import { defaultLanguage } from '../../globalVariables';
import translations from '../../translations';

const userLang = (localStorage.getItem('user_lang')) ? localStorage.getItem('user_lang') : defaultLanguage;

const initialState = {
  dictionary: translations[userLang],
  lang: userLang,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_LANGUAGE_DICTIONARY:
      return {
        ...state,
        lang: action.lang,
        dictionary: translations[action.lang],
      };
    default:
      return state;
  }
}
