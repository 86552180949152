// # Core
import React, { PureComponent } from 'react';
import {
  objectOf, shape, string,
} from 'prop-types';

// # Redux
import { connect } from 'react-redux';

// # Components
import { Col, Container, Row } from 'reactstrap';
import NetworkStatistic from '../../../components/NetworkStatistic';
import NetworkLogs from '../../../components/NetworkLogs';
import NetworkMap from '../../../components/NetworkMap';
import NetworkTopLoadedDevices from '../../../components/NetworkTopLoadedDevices';


class NetworkPage extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
    match: shape({
      params: shape({
        id: string.isRequired,
      }),
    }).isRequired,
  }

  render() {
    const {
      dictionary,
      match: { params: { id } },
    } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{dictionary.network} #{id}</h3>
          </Col>
        </Row>
        <Row>
          <NetworkStatistic />
          <NetworkLogs />
        </Row>
        <Row>
          <NetworkMap />
        </Row>
        <Row>
          <NetworkTopLoadedDevices />
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(NetworkPage);
