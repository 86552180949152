// Core
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';

// Components
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarLanguage from './TopbarLanguage';
import TopbarProfile from './TopbarProfile';
import lightLogo from './lightLogo.svg';
import darkLogo from './darkLogo.svg';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: func.isRequired,
    changeSidebarVisibility: func.isRequired,
    logout: func.isRequired,
    theme: shape({
      className: string.isRequired,
    }).isRequired,
  };

  render() {
    const {
      theme,
      logout,
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
    } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/">
              <img src={(theme.className === 'theme-dark') ? lightLogo : darkLogo} alt="Omnicampus logo" />
            </Link>
          </div>
          <div className="topbar__right">
            <TopbarLanguage />
            <TopbarProfile logout={logout} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme } = state;

  return {
    theme,
  };
};

export default connect(mapStateToProps)(Topbar);
