// # Core
import React, { PureComponent } from 'react';
import { func, objectOf, string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';

// # Components
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';


class SidebarContent extends PureComponent {
  static propTypes = {
    changeToDark: func.isRequired,
    changeToLight: func.isRequired,
    logout: func.isRequired,
    dictionary: objectOf(string).isRequired,
  };

  render() {
    const {
      changeToDark, changeToLight, dictionary, logout,
    } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title={dictionary.homePage} icon="home" route="/" />
          {/* <SidebarCategory title={dictionary.network} icon="cog">
            <SidebarLink title={dictionary.availableNetworks} route="/networks" />
            <SidebarLink title={dictionary.sspList} route="/ssps" />
          </SidebarCategory>
          <SidebarLink title={dictionary.orders} icon="cart" route="/orders" />
          <SidebarLink title={dictionary.companies} icon="briefcase" route="/companies" />
          <SidebarLink title={dictionary.users} icon="users" route="/users" /> */}
        </ul>
        <ul className="sidebar__block">
          {/* <SidebarLink title={dictionary.catalog} icon="database" route="/catalog" /> */}
          <SidebarCategory title={dictionary.settings} icon="cog">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">{dictionary.themeLight}</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">{dictionary.themeDark}</p>
            </button>
          </SidebarCategory>
          {/* <SidebarLink title={dictionary.profile} icon="user" route="/profile" /> */}
        </ul>
        <ul className="sidebar__block">
          <SidebarLink
            title={dictionary.logOut}
            icon="exit"
            onClick={logout}
            route="/login"
            haveActiveStateClassName={false}
          />
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(SidebarContent);
