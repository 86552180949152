// # Core
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { objectOf, string } from 'prop-types';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';

// # Components
import NetworkItem from '../../../components/NetworkItem';
import NetworkAddItem from '../../../components/NetworkAddItem';

// # Instruments
import { DEMO } from '../../../globalVariables';
import { getUniqueID } from '../../../helpers';

// # Fake data
import fakeData from './fakeData.json';


class Networks extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();

    this.state = {
      networkItemsData: fakeData,
    };
  }

  componentDidMount() {
    this.getNetworksData();
  }

  getNetworksData = async () => {
    if (!DEMO) {
      // TODO: Запрос на получение списка доступных сетей
      await axios.post('/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then(res => console.log(res))
        .catch(error => console.error(error));
    }

    return '';
  }

  renderNetworksList = () => {
    const { networkItemsData } = this.state;

    return networkItemsData.map(networkData => (
      <NetworkItem key={getUniqueID()} networkData={networkData} />
    ));
  }

  render() {
    const { dictionary } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{dictionary.availableNetworks}</h3>
          </Col>
        </Row>
        <Row>
          { this.renderNetworksList() }
          <NetworkAddItem />
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};


export default connect(mapStateToProps)(Networks);
