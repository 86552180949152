// # Core
import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import {
  string, bool, element, arrayOf,
} from 'prop-types';


class SidebarCategory extends Component {
  static propTypes = {
    title: string.isRequired,
    icon: string,
    isNew: bool,
    children: arrayOf(element).isRequired,
  };

  static defaultProps = {
    icon: '',
    isNew: false,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggleCollapseSidebarCategory = () => (
    this.setState(prevState => ({ collapse: !prevState.collapse }))
  );

  render() {
    const { collapse } = this.state;
    const {
      title, icon, isNew, children,
    } = this.props;

    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
    });

    return (
      <div className={categoryClass}>
        <button type="button" className="sidebar__link sidebar__category" onClick={this.toggleCollapseSidebarCategory}>
          {
            icon
              ? <span className={`sidebar__link-icon lnr lnr-${icon}`} />
              : ''
          }

          <p className="sidebar__link-title">
            {title}
            {isNew && <span className="sidebar__category-new" />}
          </p>

          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>

        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <div className="sidebar__submenu">
            {children}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default SidebarCategory;
