// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
// import { Link } from 'react-router-dom';
// import axios from 'axios';

// # Redux
import { connect } from 'react-redux';

// # Components
import ArrowDownwardIcon from 'mdi-react/ArrowDownwardIcon';
import { Col, Card, CardBody } from 'reactstrap';

// # Instruments
import { getUniqueID } from '../../helpers';


class NetworkLogs extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  render() {
    const { dictionary } = this.props;

    return (
      <Col md={12} xl={3} lg={4}>
        <Card>
          <CardBody>
            <div className="network-logs__body">
              <div className="card__title">
                <h5 className="bold-text">{dictionary.networkEvents}</h5>
              </div>

              <div className="network-logs__list">
                {
                  [...Array(8)].map(() => (
                    <p key={getUniqueID()}>#3213232 Устройство 410021 установило связь с сервером.</p>
                  ))
                }
              </div>

              <div className="dashboard__booking-reservations-link network-logs__read-more">
                { dictionary.readMore } <ArrowDownwardIcon className="dashboard__booking-reservations-link-icon" />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(NetworkLogs);
