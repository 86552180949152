// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import axios from 'axios';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Brush, Legend, ResponsiveContainer,
} from 'recharts';

// # Redux
import { connect } from 'react-redux';

// # Components
import Panel from '../Panel';

// # Instruments
import { getUniqueID } from '../../helpers';
import { API_URL, DEMO } from '../../globalVariables';

// # Temporary
import fakeData from './fakeData.json';


class DashboardOrdersStatisticByDate extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();

    this.state = {
      data: fakeData,
    };

    this.getTerminalOrdersStatistic = this.getTerminalOrdersStatistic.bind(this);
    this.renderAreas = this.renderAreas.bind(this);
  }

  componentDidMount() {
    this.getTerminalOrdersStatistic();
  }

  getTerminalOrdersStatistic = async () => {
    if (!DEMO) {
      await axios.get(`${API_URL}/modules/orders/period/stat/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const {
            success,
            data,
          } = res.data;

          return (success) ? this.setState({ data }) : fakeData;
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  renderAreas = () => {
    const { dictionary } = this.props;

    const areasSettings = [
      {
        name: dictionary.reserved,
        dataKey: 'reserved',
        fill: '#ff6384',
        stroke: '#ff6384',
      },
      {
        name: dictionary.placed,
        dataKey: 'placed',
        fill: '#b0e685',
        stroke: '#b0e685',
      },
      {
        name: dictionary.receipt,
        dataKey: 'receipt',
        fill: '#36a2eb',
        stroke: '#36a2eb',
      },
      {
        name: dictionary.extracted,
        dataKey: 'extracted',
        fill: '#f7de74',
        stroke: '#f7de74',
      },
      {
        name: dictionary.extract,
        dataKey: 'prepared_to_withdrawn',
        fill: '#3b3be8',
        stroke: '#3b3be8',
      },
      {
        name: dictionary.canceled,
        dataKey: 'canceled',
        fill: '#e7e9ed',
        stroke: '#e7e9ed',
      },
    ];

    return areasSettings.map((areaSettings) => {
      const {
        name, dataKey, fill, stroke,
      } = areaSettings;

      return (
        <Area
          key={getUniqueID()}
          name={name}
          type="monotone"
          dataKey={dataKey}
          fill={fill}
          stroke={stroke}
          fillOpacity={0.2}
        />
      );
    });
  }

  render() {
    const { dictionary } = this.props;
    const { data } = this.state;

    return (
      <Panel
        xl={9}
        lg={12}
        md={12}
        xs={12}
        title={dictionary.ordersStatisticForPeriod}
        subhead={dictionary.ordersStatusInformation}
      >
        <ResponsiveContainer height={280} className="dashboard__area">
          <AreaChart data={data.reverse()} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="date" tickLine={false} />
            <YAxis tickLine={false} />
            <Tooltip />
            <Legend />
            <CartesianGrid />
            <Brush dataKey="date" height={12} stroke="#f2f4f7" fill="#f2f4f7" />

            {this.renderAreas()}
          </AreaChart>
        </ResponsiveContainer>
      </Panel>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(DashboardOrdersStatisticByDate);
