// # Core
import React, { Component, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';

// # Redux
import { Provider } from 'react-redux';

// # Components
import ScrollToTop from './ScrollToTop';
import Router from './Router';

// # Redux
import store from '../../redux/store';

// # Instruments
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import { updateUserLoginState } from '../../redux/actions/userActions';


class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    const hasAccessToken = localStorage.getItem('access_token');

    if (hasAccessToken) {
      store.dispatch(updateUserLoginState(true));
    }

    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  renderLoader = () => {
    const { loading } = this.state;

    return (
      <div className={`load${loading ? '' : ' loaded'}`}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    const { loaded } = this.state;

    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              {!loaded && this.renderLoader()}
              <Router />
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
