// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';

// # Components
import { Col, Container, Row } from 'reactstrap';
import NetworkForm from '../../../components/NetworkForm';

// # Instruments
import { API_URL, DEMO } from '../../../globalVariables';
import fakeData from './fakeData.json';


class EditNetworkPage extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();

    this.state = {
      networkData: fakeData,
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    console.log(this.props);
    console.log(event);
  }

  getNetworkData = async () => {
    if (!DEMO) {
      // TODO: Запрос на получение информации по сети
      await axios.post(`${API_URL}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then(res => console.log(res))
        .catch(error => console.log(error));
    }

    return '';
  }

  render() {
    const { dictionary } = this.props;
    const { networkData } = this.state;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{dictionary.editNetwork}</h3>
            <h3 className="page-subhead subhead">{dictionary.editNetworkSubtitle}</h3>
          </Col>
        </Row>
        <Row>
          <NetworkForm networkData={networkData} onSubmit={this.onSubmit} />
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(EditNetworkPage);
