// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import axios from 'axios';
import {
  Area, AreaChart, ResponsiveContainer,
} from 'recharts';
import { Table } from 'reactstrap';

// # Redux
import { connect } from 'react-redux';

// # Components
import Panel from '../Panel';

// # Instruments
import { API_URL, DEMO } from '../../globalVariables';
import { getUniqueID } from '../../helpers';

// # Fake data
import fakeData from './fakeData.json';


class DashboardTopLoadedDevices extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();

    this.state = {
      topLoadedDevices: fakeData,
    };
  }

  componentDidMount() {
    this.getTopLoadedDevicesInfo();
  }

  getTopLoadedDevicesInfo = async () => {
    if (!DEMO) {
      // TODO: запрос на получение данных о топ 5 загруженных устройствах сети
      await axios.get(`${API_URL}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const { success, data } = res.data;

          if (success) {
            return this.setState({ topLoadedDevices: data });
          }

          return null;
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  render() {
    const { topLoadedDevices } = this.state;
    const { dictionary } = this.props;

    return (
      <Panel lg={12} title={dictionary.networkTopLoadedDevices}>
        <Table responsive className="table--bordered dashboard__table-crypto">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>{dictionary.address}</th>
              <th>{dictionary.modules}</th>
              <th>{dictionary.cells}</th>
              <th>{dictionary.loadAvarage}</th>
              <th>{dictionary.sevenDaysChart}</th>
            </tr>
          </thead>
          <tbody>
            {topLoadedDevices.map((device, index) => (
              <tr key={getUniqueID()}>
                <td>{index + 1}</td>
                <td>{device.id}</td>
                <td>{device.address}</td>
                <td>{device.stacks}</td>
                <td>{device.cells}</td>
                <td>{device.load_avarage}%</td>
                <td className="dashboard__table-crypto-chart">
                  <ResponsiveContainer height={36}>
                    <AreaChart data={device.placed}>
                      <Area
                        name={dictionary.orders}
                        type="monotone"
                        dataKey="count"
                        fill="#4ce1b6"
                        stroke="#4ce1b6"
                        fillOpacity={0.2}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(DashboardTopLoadedDevices);
