// # Pages
import LogIn from './LogIn';
import Dashboard from './Dashboard';
import NetworksListPage from './Networks/NetworksListPage';
import CreateNetworkPage from './Networks/CreateNetworkPage';
import EditNetworkPage from './Networks/EditNetworkPage';
import NetworkPage from './Networks/NetworkPage';

const pages = {
  LogIn,
  Dashboard,
  NetworksListPage,
  CreateNetworkPage,
  EditNetworkPage,
  NetworkPage,
};

export default pages;
