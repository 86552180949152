// # Core
import React, { PureComponent } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import { shape, bool, func } from 'prop-types';

// # Components
import SidebarContent from './SidebarContent';


class Sidebar extends PureComponent {
  static propTypes = {
    sidebar: shape({
      show: bool,
      collapse: bool,
    }).isRequired,
    logout: func.isRequired,
    changeToDark: func.isRequired,
    changeToLight: func.isRequired,
    changeMobileSidebarVisibility: func.isRequired,
  }

  render() {
    const {
      changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar, logout,
    } = this.props;

    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              logout={logout}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              logout={logout}
            />
          </div>
        </Scrollbar>
      </div>
    );
  }
}

export default Sidebar;
