// # Core
import React, { PureComponent } from 'react';
import {
  func, string, shape, arrayOf, bool, oneOfType,
} from 'prop-types';

// # Components
import Select from 'react-select';


class SelectField extends PureComponent {
  static propTypes = {
    onChange: func.isRequired,
    name: string.isRequired,
    placeholder: string,
    options: arrayOf(shape({
      value: string,
      label: string,
    })),
    value: oneOfType([
      string,
      shape({
        value: string,
        label: string,
      }),
    ]).isRequired,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  handleChange = (selectedOption) => {
    const { onChange } = this.props;
    onChange(selectedOption);
  };

  render() {
    const {
      value, name, placeholder, options,
    } = this.props;

    return (
      <Select
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
      />
    );
  }
}

const renderSelectField = (props) => {
  const {
    input, meta, options, placeholder,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...input}
        options={options}
        placeholder={placeholder}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: shape({
    onChange: func,
    name: string,
  }).isRequired,
  meta: shape({
    touched: bool,
    error: string,
  }),
  options: arrayOf(shape({
    value: string,
    label: string,
  })),
  placeholder: string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default renderSelectField;
