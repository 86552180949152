/* eslint-disable react/prop-types */
// # Core
import React, { PureComponent } from 'react';
import {
  bool, string, number, element,
} from 'prop-types';

// # Components
import {
  Badge, Card, CardBody, Col,
} from 'reactstrap';


class AlertComponent extends PureComponent {
  static propTypes = {
    md: number,
    lg: number,
    xl: number,
    sm: number,
    xs: number,
    icon: string,
    divider: bool,
    color: string,
    title: string,
    label: string,
    before: element,
    subhead: string,
    panelClass: string,
  };

  static defaultProps = {
    md: 0,
    lg: 0,
    xl: 0,
    sm: 0,
    xs: 0,
    icon: '',
    color: '',
    title: '',
    label: '',
    subhead: '',
    before: null,
    divider: false,
    panelClass: '',
  };

  render() {
    const {
      md, lg, xl, sm, xs, color, divider, icon, title, label, subhead, before,
      panelClass, children,
    } = this.props;

    return (
      <Col md={md} lg={lg} xl={xl} sm={sm} xs={xs}>
        <Card
          className={`panel${color ? ` panel--${color}` : ''}
          ${divider ? ' panel--divider' : ''} ${panelClass}`}
        >
          <CardBody className="panel__body">
            <div className="panel__title">
              <h5 className="bold-text">
                {icon ? <span className={`panel__icon lnr lnr-${icon}`} /> : ''}
                {title}
                <Badge className="panel__label">{label}</Badge>
              </h5>
              <h5 className="subhead">{subhead}</h5>
            </div>
            <div className="panel__content">
              {children}
            </div>
          </CardBody>
        </Card>
        {before}
      </Col>
    );
  }
}

export const PanelTitle = ({ title }) => (
  <div className="panel__title">
    <h5 className="bold-text">
      {title}
    </h5>
  </div>
);

export default AlertComponent;
