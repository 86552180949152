// # Core
import React, { PureComponent } from 'react';
import { objectOf, string, func } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios';

import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';

// # Redux
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';


// # Instruments
import { API_URL } from '../../globalVariables';

// # Actions
import { updateUserLoginState } from '../../redux/actions/userActions';


class LogIn extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
    dispatch: func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  }

  constructor() {
    super();

    this.state = {
      showPassword: false,
      username: '',
      password: '',
      usernameError: false,
      passwordError: false,
      submitError: false,
    };
  }

  onInputChange = (event) => {
    const { value, name } = event.target;
    return this.setState({ [name]: value });
  }

  showPassword = (event) => {
    event.preventDefault();
    return this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  isValidForm = () => {
    const { username, password } = this.state;

    const isEmptyUserName = Boolean(!username);
    const isEmptyPassword = Boolean(!password);

    this.setState({
      usernameError: isEmptyUserName,
      passwordError: isEmptyPassword,
    });

    return !(isEmptyUserName && isEmptyPassword);
  }

  onSubmit = async (event) => {
    event.preventDefault();

    if (this.isValidForm()) {
      const { username, password } = this.state;

      await axios.post(`${API_URL}/login/`, { login: username, password })
        .then((res) => {
          const { success, data } = res.data;

          if (success) {
            const { dispatch, history } = this.props;

            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('user_data', JSON.stringify(data));

            dispatch(updateUserLoginState(true));

            return history.push('/');
          }

          return this.setState({ submitError: true });
        })
        .catch(() => this.setState({ submitError: true }));
    }
  }

  renderLoginForm = () => {
    const { dictionary } = this.props;
    const {
      usernameError,
      passwordError,
      showPassword,
      submitError,
      username,
      password,
    } = this.state;

    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{dictionary.userName}:</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon"><AccountOutlineIcon /></div>
            <Field
              type="text"
              name="username"
              component="input"
              placeholder="Name"
              onChange={this.onInputChange}
              value={username}
            />
          </div>
          {
            usernameError
            && <div className="form__form-group-error">{dictionary.errorEmptyField}</div>
          }
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">{dictionary.password}:</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon"><KeyVariantIcon /></div>
            <Field
              name="password"
              component="input"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={this.onInputChange}
              value={password}
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={this.showPassword}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {
            passwordError
            && <div className="form__form-group-error">{dictionary.errorEmptyField}</div>
          }
        </div>

        <button
          className="btn btn-primary account__btn account__btn--small"
          type="submit"
        >
          Sign In
        </button>
        {
          submitError
          && <div className="form__form-group-error u-center">{dictionary.errorInvalidLoginOrPassword}</div>
        }
      </form>
    );
  };

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Welcome to
                <span className="account__logo"> OMNIC
                  <span className="account__logo-accent"> Dashboard</span>
                </span>
              </h3>
              <h4 className="account__subhead subhead">Lets get started</h4>
            </div>
            {this.renderLoginForm()}
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;
  const { isLogined } = state.user;

  return {
    dictionary,
    isLogined,
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'log_in_form',
})(LogIn));
