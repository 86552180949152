// # Core
import React, { PureComponent } from 'react';
import { objectOf, string, shape } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';

// # Components
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  PieChart, Pie, Tooltip, Legend, Cell,
} from 'recharts';

// # Instruments
import { defaultColors, DEMO, API_URL } from '../../globalVariables';
import { getUniqueID, addThousantSeparators } from '../../helpers';


// # Fake data
import fakeData from './fakeData.json';


class NetworkStatistic extends PureComponent {
  static propTypes = {
    className: string.isRequired,
    dictionary: objectOf(string).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    match: shape({
      params: shape({
        id: string.isRequired,
      }),
    }).isRequired,
  }

  constructor() {
    super();

    this.state = fakeData;
  }

  async getNetworkStatisticData() {
    if (!DEMO) {
      await axios.get(`${API_URL}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const { success, data } = res.data;

          if (success) {
            const { loadedData, devicesData } = data;
            return this.setState({ loadedChartData: loadedData, devicesChartData: devicesData });
          }

          return null;
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  render() {
    const {
      className,
      dictionary,
      history,
      match: {
        params: {
          id,
        },
      },
    } = this.props;

    const {
      owner,
      orders,
      devices,
      createDate,
      companiesUse,
      networkTitle,
      loadedChartData,
      devicesChartData,
      networkDescription,
    } = this.state;

    const loadedPieData = [
      { name: dictionary.reserved, value: loadedChartData.reserved },
      { name: dictionary.placed, value: loadedChartData.placed },
      { name: dictionary.extract, value: loadedChartData.prepared_to_withdrawn },
    ];

    const devicesPieData = [
      { name: dictionary.online, value: devicesChartData.online },
      { name: dictionary.offline, value: devicesChartData.offline },
      { name: dictionary.repair, value: devicesChartData.repair },
    ];

    const legentWrapperStyle = {
      top: 30,
      left: 240,
      width: 150,
      lineHeight: '24px',
    };

    const toolTipItemStyleStyle = {
      color: (className === 'theme-dark') ? '#ffffff' : '#333333',
      fontSize: 13,
    };

    return (
      <Col md={12} lg={8} xl={9}>
        <Card>
          <CardBody>
            <div className="project-summary">
              <div className="card__title">
                <h5 className="bold-text">{networkTitle}</h5>
              </div>

              <Button
                className="btn btn-outline-secondary project-summary__btn"
                outline
                size="sm"
                onClick={() => history.push(`/network/${id}/edit`)}
              >
                {dictionary.edit}
              </Button>

              <table className="project-summary__info">
                <tbody>
                  <tr>
                    <th>{dictionary.owner}:</th>
                    <td>{owner}</td>
                  </tr>
                  <tr>
                    <th>{dictionary.created}:</th>
                    <td>{createDate}</td>
                  </tr>
                </tbody>
              </table>

              <p>{networkDescription}</p>

              <div className="project-summary__stats">
                <div className="project-summary__stat">
                  <p>{addThousantSeparators(devices)} <span>{dictionary.networkDevices}</span></p>
                </div>
                <div className="project-summary__stat">
                  <p>{addThousantSeparators(orders)} <span>{dictionary.networkOrders}</span></p>
                </div>
                <div className="project-summary__stat">
                  <p>{addThousantSeparators(companiesUse)} <span>{dictionary.networkCompaniesUse}</span></p>
                </div>
              </div>

              <hr />

              <div className="project-summary__statistics">
                <div className="project-summary__statistic">
                  <p className="project-summary__statistic-title">{dictionary.currentLoad}</p>
                  <PieChart width={240} height={220}>
                    <Tooltip itemStyle={toolTipItemStyleStyle} />
                    <Pie
                      data={loadedPieData}
                      innerRadius={60}
                      outerRadius={80}
                      dataKey="value"
                      cx={110}
                      cy={110}
                      label
                    >
                      {
                        loadedPieData.map((_, index) => (
                          <Cell key={getUniqueID()} fill={defaultColors[index]} stroke="none" />
                        ))
                      }
                    </Pie>
                    <Legend layout="vertical" verticalAlign="middle" wrapperStyle={legentWrapperStyle} />
                  </PieChart>
                </div>
                <div className="project-summary__statistic">
                  <p className="project-summary__statistic-title">{dictionary.devices}</p>
                  <PieChart width={240} height={250}>
                    <Tooltip itemStyle={toolTipItemStyleStyle} />
                    <Pie
                      data={devicesPieData}
                      innerRadius={60}
                      outerRadius={80}
                      dataKey="value"
                      cx={110}
                      cy={110}
                      label
                    >
                      {
                        devicesPieData.map((_, index) => (
                          <Cell key={getUniqueID()} fill={defaultColors[index]} stroke="none" />
                        ))
                      }
                    </Pie>
                    <Legend layout="vertical" verticalAlign="middle" wrapperStyle={legentWrapperStyle} />
                  </PieChart>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


const mapStateToProps = (state) => {
  const { className } = state.theme;
  const { dictionary } = state.language;

  return {
    className,
    dictionary,
  };
};

export default withRouter(connect(mapStateToProps)(NetworkStatistic));
