// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';

// # Redux
import { connect } from 'react-redux';

// # Components
import { Col, Container, Row } from 'reactstrap';
import NetworkForm from '../../../components/NetworkForm';


class CreateNetworkPage extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  onSubmit = (event) => {
    event.preventDefault();
    console.log(this.props);
    console.log(event);
  }

  render() {
    const { dictionary } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{dictionary.createNewNetwork}</h3>
            <h3 className="page-subhead subhead">{dictionary.createNewNetworkSubtitle}</h3>
          </Col>
        </Row>
        <Row>
          <NetworkForm onSubmit={this.onSubmit} />
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(CreateNetworkPage);
