// Core
import React, { PureComponent, Fragment } from 'react';
import { func } from 'prop-types';
import { Collapse } from 'reactstrap';

// Components
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarMenuLink from './TopbarMenuLink';


export default class TopbarProfile extends PureComponent {
  static propTypes = {
    logout: func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  renderAvatar = () => {
    const {
      email,
      first_name: firstName,
      last_name: lastName,
    } = JSON.parse(localStorage.getItem('user_data'));

    let name = '';
    let abbreviation = '';

    if (firstName) {
      name += firstName;
      abbreviation += firstName.toUpperCase().charAt(0);
    }

    if (lastName) {
      name += ` ${lastName}`;
      abbreviation += lastName.toUpperCase().charAt(0);
    }

    if (!name) {
      name = email;
      abbreviation += email.toUpperCase().charAt(0);
    }

    return (
      <Fragment>
        <div className="topbar__avatar-abbreviation">{ abbreviation }</div>
        <p className="topbar__avatar-name">{ name.trim() }</p>
        <DownIcon className="topbar__icon" />
      </Fragment>
    );
  }

  render() {
    const { logout } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>{ this.renderAvatar() }</button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
            <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
            <div className="topbar__menu-divider" /> */}
            <TopbarMenuLink title="Log Out" icon="exit" path="/logout" onClick={logout} />
          </div>
        </Collapse>
      </div>
    );
  }
}
