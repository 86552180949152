/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

// # Core
import React, { PureComponent, Fragment } from 'react';
import { objectOf, string } from 'prop-types';
import { compose, withProps, withStateHandlers } from 'recompose';

// # Redux
import { connect } from 'react-redux';

// # Components
import { Card, CardBody, Col } from 'reactstrap';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs,
} from 'react-google-maps';

// # Components
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';

// # Instruments
// import { GOOGLE_MAPS_API_KEY } from '../../globalVariables';


// const googleMapUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp`;


const MapWithAMarker = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD84CRFR44xSC242F5rPodUZ3CqKbUlqMw&v=3.'
    + 'exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '360px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withStateHandlers(() => ({
    isOpen: true,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    }),
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 50.435016, lng: 30.5410406 }}
  >
    {props.isMarkerShown
      && (
      <Fragment>
        <Marker position={{ lat: 50.435016, lng: 30.5410406 }} onClick={props.onToggleOpen}>
          {props.isOpen
          && (
          <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
            <div className="map__marker-label">
              <div className="map__marker-label-content">
                Новая почта #3321
              </div>
            </div>
          </InfoBox>
          )}
        </Marker>
        <Marker position={{ lat: 50.433800, lng: 30.54124102 }} onClick={props.onToggleOpen}>
          {props.isOpen
          && (
          <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
            <div className="map__marker-label">
              <div className="map__marker-label-content">
                Новая почта #3322
              </div>
            </div>
          </InfoBox>
          )}
        </Marker>
      </Fragment>
      )}
  </GoogleMap>
));

class NetworkMap extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  render() {
    const { dictionary } = this.props;


    return (
      <Col xs={12} md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{dictionary.coverage}</h5>
            </div>
            <MapWithAMarker isMarkerShown />
          </CardBody>
        </Card>
      </Col>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(NetworkMap);
