// # Core
import React, { PureComponent } from 'react';
import { string, bool, func } from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';


class SidebarLink extends PureComponent {
  static propTypes = {
    title: string.isRequired,
    icon: string,
    newLink: bool,
    route: string,
    onClick: func,
    haveActiveStateClassName: bool,
  }

  static defaultProps = {
    icon: '',
    newLink: false,
    route: '/',
    onClick: () => {},
    haveActiveStateClassName: true,
  }

  render() {
    const {
      title, icon, newLink, route, onClick, haveActiveStateClassName,
    } = this.props;

    return (
      <NavLink
        to={route}
        onClick={onClick}
        activeClassName={(haveActiveStateClassName) ? 'sidebar__link-active' : ''}
      >
        <li className="sidebar__link">
          {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
          <p className="sidebar__link-title">
            {title}
            {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
          </p>
        </li>
      </NavLink>
    );
  }
}

export default SidebarLink;
