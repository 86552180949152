// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import { Link } from 'react-router-dom';

// # Redux
import { connect } from 'react-redux';

// # Component
import Panel from '../Panel';

// # Instruments
import TreeIcon from './tree-icon.svg';


class NetworkAddItem extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  render() {
    const { dictionary } = this.props;

    return (
      <Panel
        lg={6}
        xl={3}
        md={12}
      >
        <Link className="network-add__link" to="/network/create" />
        <div className="network-add__title">{dictionary.addNetwork}</div>
        <div className="network-add__subtitle">{dictionary.createNewNetwork}</div>
        <div className="network-add__plus-icon" />
        <div className="network-add__tree-icon">
          <img src={TreeIcon} alt="Network tree icon" />
        </div>
      </Panel>
    );
  }
}


const mapStateToProps = (state) => {
  const { className } = state.theme;
  const { dictionary } = state.language;

  return {
    className,
    dictionary,
  };
};

export default connect(mapStateToProps)(NetworkAddItem);
