// # Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import {
  objectOf, string, shape, bool, func,
} from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios';

// # Redux
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

// # Components
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import renderSelectField from '../Select';

// # Instruments
import { DEMO } from '../../globalVariables';

// # Fake data
import fakeData from './fakeData.json';


const renderField = ({
  input, placeholder, type, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: shape().isRequired,
  placeholder: string,
  type: string,
  meta: shape({
    touched: bool,
    error: string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};


class NetworkForm extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
    onSubmit: func.isRequired,
    networkData: objectOf(string),
    history: ReactRouterPropTypes.history.isRequired,
  }

  static defaultProps = {
    networkData: {
      networkName: '',
      networkOwnerId: '',
      networkDescription: '',
    },
  }

  constructor() {
    super();

    this.state = {
      ownersList: fakeData,
      submitError: false,
      networkNameError: false,
      networkOwnerError: false,
      networkDescriptionError: false,
    };
  }

  componentDidMount() {
    const { networkData } = this.props;

    this.getOwnersList();
    this.setState({ ...networkData });
  }

  getOwnersList = async () => {
    if (!DEMO) {
      // TODO: Запрос на получение списка owner для select
      await axios.post('/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then(res => console.log(res))
        .catch(error => console.log(error));
    }

    return '';
  }

  onInputChange = (event) => {
    const { value, name } = event.target;
    return this.setState({ [name]: value });
  }

  onSelectChange = (event) => {
    const { value, label } = event;
    console.log(value, label);
    return this.setState({ networkOwnerId: value });
  }

  isValidForm = () => {
    const { networkName, networkOwnerId, networkDescription } = this.state;

    const isEmptyNetworkNameField = Boolean(!networkName);
    const isEmptyNetworkOwnerField = Boolean(!networkOwnerId);
    const isEmptyNetworkDescriptionField = Boolean(!networkDescription);

    this.setState({
      networkNameError: isEmptyNetworkNameField,
      networkOwnerError: isEmptyNetworkOwnerField,
      networkDescriptionError: isEmptyNetworkDescriptionField,
    });

    return !(isEmptyNetworkNameField && isEmptyNetworkOwnerField && isEmptyNetworkDescriptionField);
  }

  onCancelClick = () => {
    const { history } = this.props;

    return history.goBack();
  }

  onNetworkFormSubmit = (event) => {
    event.preventDefault();

    if (this.isValidForm()) {
      const { onSubmit } = this.props;
      console.log('onNetworkFormSubmitFunction', onSubmit);

      // TODO: доработать функционал отправки формы на сервер
      console.log('Valid form');
    }

    return false;
  }

  render() {
    const { dictionary } = this.props;
    const {
      ownersList,
      submitError,
      networkNameError,
      networkOwnerError,
      networkDescriptionError,
    } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={this.onNetworkFormSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">{dictionary.networkNameLabel}</span>
                <div className="form__form-group-field">
                  <Field
                    name="networkName"
                    type="text"
                    onChange={this.onInputChange}
                    component={renderField}
                    placeholder={dictionary.networkNamePlaceholder}
                  />
                </div>
                {
                  networkNameError
                  && <div className="form__form-group-error">{dictionary.errorEmptyField}</div>
                }
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{dictionary.networkOwnersLabel}</span>
                <div className="form__form-group-field">
                  <Field
                    name="networkOwner"
                    type="text"
                    options={ownersList}
                    onChange={this.onSelectChange}
                    component={renderSelectField}
                    placeholder={dictionary.networkOwnersPlaceholder}
                  />
                </div>
                {
                  networkOwnerError
                  && <div className="form__form-group-error">{dictionary.errorEmptySelectValue}</div>
                }
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{dictionary.networkDescriptionLabel}</span>
                <div className="form__form-group-field">
                  <Field
                    name="networkDescription"
                    type="input"
                    onChange={this.onInputChange}
                    component={renderField}
                    placeholder={dictionary.networkDescriptionPlaceholder}
                  />
                </div>
                {
                  networkDescriptionError
                  && <div className="form__form-group-error">{dictionary.errorEmptyField}</div>
                }
              </div>

              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">{dictionary.save}</Button>
                <Button type="button" onClick={this.onCancelClick}>{dictionary.cancel}</Button>
              </ButtonToolbar>

              {
                submitError
                && <div className="form__form-group-error u-center">{dictionary.serverError}</div>
              }
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default withRouter(connect(mapStateToProps)(reduxForm({
  form: 'network_form',
})(NetworkForm)));
