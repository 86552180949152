// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import { Col, Container, Row } from 'reactstrap';

// # Redux
import { connect } from 'react-redux';

// # Components
import DashboardDevicesOnline from '../../components/DashboardDevicesOnline';
import DashboardCellsLoad from '../../components/DashboardCellsLoad';
import DashboardReservedCells from '../../components/DashboardReservedCells';
// import DashboardCellsLoadAvarage from '../../components/DashboardCellsLoadAvarage';
import DashboardOrdersStatistic from '../../components/DashboardOrdersStatistic';
import DashboardOrdersStatisticByDate from '../../components/DashboardOrdersStatisticByDate';
import DashboardTopLoadedDevices from '../../components/DashboardTopLoadedDevices';


class Dashboard extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  render() {
    const { dictionary } = this.props;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{dictionary.homePage}</h3>
          </Col>
        </Row>
        <Row>
          <DashboardDevicesOnline />
          <DashboardCellsLoad />
          <DashboardReservedCells />
          {/* <DashboardCellsLoadAvarage /> */}
        </Row>
        <Row>
          <DashboardOrdersStatistic />
          <DashboardOrdersStatisticByDate />
        </Row>
        <Row>
          <DashboardTopLoadedDevices />
        </Row>
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};


export default connect(mapStateToProps)(Dashboard);
