// # Core
import React, { PureComponent } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { shape, string, bool } from 'prop-types';

// # Redux
import { connect } from 'react-redux';

// # Pages
import pages from '../../pages';

// # Components
import Layout from '../Layout/index';


class Router extends PureComponent {
  static propTypes = {
    theme: shape({
      className: string,
    }).isRequired,
    isLogined: bool.isRequired,
  };

  withoutAccessToken = () => (
    <Route path="/" component={pages.LogIn} />
  );

  withAccessToken = () => (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <Route exact path="/" component={pages.Dashboard} />
          {/* <Route path="/networks" component={pages.NetworksListPage} /> */}
          <Route path="/logout" component={pages.LogIn} />

          {/* <Route path="/network/create" component={pages.CreateNetworkPage} /> */}
          {/* <Route exact path="/network/:id" component={pages.NetworkPage} /> */}
          {/* <Route path="/network/:id/edit" component={pages.EditNetworkPage} /> */}
        </Switch>
      </div>
    </div>
  );

  render() {
    const { theme, isLogined } = this.props;

    return (
      <div className={theme.className}>
        <div className="wrapper">
          {
            (isLogined)
              ? this.withAccessToken()
              : this.withoutAccessToken()
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme } = state;
  const { isLogined } = state.user;

  return {
    theme,
    isLogined,
  };
};

export default withRouter(connect(mapStateToProps)(Router));
