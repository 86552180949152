// # Core
import React, { PureComponent } from 'react';
import { objectOf, string } from 'prop-types';
import axios from 'axios';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';

// # Redux
import { connect } from 'react-redux';

// # Instruments
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { API_URL, DEMO } from '../../globalVariables';

// # Fake data
import fakeData from './fakeData.json';


class DashboardReservedCells extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
  }

  constructor() {
    super();
    this.state = fakeData;

    this.getTerminalReservedCellsData = this.getTerminalReservedCellsData.bind(this);
  }

  componentDidMount() {
    this.getTerminalReservedCellsData();
  }

  getTerminalReservedCellsData = async () => {
    if (!DEMO) {
      await axios.get(`${API_URL}/modules/total/reserved/stat/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const {
            success,
            data: {
              total_cells: totalCells,
              total_reserved: reservedCells,
              ratio: reservedCellsRatio,
            },
          } = res.data;

          return (success) ? this.setState({ totalCells, reservedCells, reservedCellsRatio }) : '';
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  render() {
    const { totalCells, reservedCells, reservedCellsRatio } = this.state;
    const { dictionary } = this.props;

    return (
      <Col md={12} xl={4} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__booking-card">
            <div className="dashboard__booking-total-container">
              <h5 className="dashboard__booking-total-title">
                {reservedCells}
              </h5>
              <TrendingUpIcon className="dashboard__trend-icon" />
            </div>
            <h5 className="dashboard__booking-total-description">{dictionary.reservedPlaces}</h5>
            <div
              className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--rounded"
            >
              <p className="dashboard__booking-card-progress-label progress__label">
                {reservedCellsRatio}% {dictionary.of} {totalCells}
              </p>
              <Progress value={reservedCellsRatio} />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


const mapStateToProps = (state) => {
  const { dictionary } = state.language;

  return {
    dictionary,
  };
};

export default connect(mapStateToProps)(DashboardReservedCells);
