
// # Core
import React, { PureComponent } from 'react';
import axios from 'axios';
import {
  string, arrayOf, objectOf, shape,
} from 'prop-types';


// # Redux
import { connect } from 'react-redux';

// # Components
import {
  Legend, Pie, Cell, PieChart, ResponsiveContainer, Tooltip,
} from 'recharts';
import Panel from '../Panel';


// # Instruments
import { API_URL, DEMO, defaultColors } from '../../globalVariables';
import { getUniqueID } from '../../helpers';

// # Fake data
import fakeData from './fakeData.json';


const renderLegend = ({ payload }) => (
  <ul className="dashboard__booking-reservations-chart-legend dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={getUniqueID()}>
          <span style={{ backgroundColor: defaultColors[index] }} />
          <p>{entry.value}</p>
        </li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: arrayOf(shape({
    color: string,
    value: string,
  })).isRequired,
};

class DashboardOrdersStatistic extends PureComponent {
  static propTypes = {
    dictionary: objectOf(string).isRequired,
    className: string,
  }

  static defaultProps = {
    className: 'theme-white',
  }

  constructor() {
    super();

    this.state = fakeData;
  }

  componentDidMount() {
    this.getOrdersInfo();
  }

  getOrdersInfo = async () => {
    if (!DEMO) {
      await axios.get(`${API_URL}/modules/orders/current/date/stat/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('access_token')}`,
        },
      })
        .then((res) => {
          const { success, data } = res.data;
          return (success) ? this.setState({ ...data }) : null;
        })
        .catch(error => console.info(error));
    }

    return '';
  }

  render() {
    const { className, dictionary } = this.props;
    const {
      placed,
      receipt,
      reserved,
      extracted,
      // total_orders: totalOrders,
      prepared_to_withdrawn: withdrawn,
    } = this.state;

    const pieDataOrders = [
      { name: dictionary.reserved, value: reserved },
      { name: dictionary.placed, value: placed },
      { name: dictionary.receipt, value: receipt },
      { name: dictionary.extracted, value: extracted },
      { name: dictionary.extract, value: withdrawn },
    ];

    const toolTipItemStyleStyle = {
      color: (className === 'theme-dark') ? '#ffffff' : '#333333',
      fontSize: 13,
    };

    return (
      <Panel
        lg={6}
        xl={3}
        md={12}
        title={dictionary.orders}
        subhead={dictionary.summaryOrdersStatistic}
        panelClass="dashboard__booking-reservations-panel"
      >
        <div className="dashboard__booking-reservations">
          <p className="dashboard__booking-reservations-title">{dictionary.totalOrdersForToday}</p>
          {/* <p className="dashboard__booking-reservations-number">{totalOrders}</p> */}
          <p className="dashboard__booking-reservations-number">
            {placed + receipt + reserved + extracted + withdrawn}
          </p>
          <div className="dashboard__booking-reservations-chart">
            <ResponsiveContainer>
              <PieChart className="dashboard__booking-reservations-chart-container">
                <Tooltip itemStyle={toolTipItemStyleStyle} offset={-300} useTranslate3d={false} />
                <Pie
                  data={pieDataOrders}
                  dataKey="value"
                  cy={80}
                  innerRadius={47}
                  outerRadius={65}
                >
                  {
                    pieDataOrders.map((_, index) => (
                      <Cell key={getUniqueID()} fill={defaultColors[index]} stroke="none" />
                    ))
                  }
                </Pie>
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  content={renderLegend}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { className } = state.theme;
  const { dictionary } = state.language;

  return {
    className,
    dictionary,
  };
};

export default connect(mapStateToProps)(DashboardOrdersStatistic);
