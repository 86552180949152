// # Core
import React, { PureComponent } from 'react';
import { string, func } from 'prop-types';
import { Collapse } from 'reactstrap';

// # Instruments
import DownIcon from 'mdi-react/ChevronDownIcon';

// # Redux
import { connect } from 'react-redux';
import { updateLanguageDictionary } from '../../redux/actions/languageActions';

import { getUniqueID } from '../../helpers';
import { languages } from '../../globalVariables';


class TopbarLanguage extends PureComponent {
  static propTypes = {
    lang: string.isRequired,
    dispatch: func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggleLangDropdown = () => this.setState(prevState => ({ collapse: !prevState.collapse }));

  changeLanguage = (lang) => {
    const { dispatch } = this.props;
    this.toggleLangDropdown();
    return dispatch(updateLanguageDictionary(lang));
  }

  render() {
    const { lang: currentLanguage } = this.props;
    const { collapse } = this.state;

    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" type="button" onClick={this.toggleLangDropdown}>
          <span className="topbar__language-btn-title">{currentLanguage.toUpperCase()}</span>
          <DownIcon className="topbar__icon" />
        </button>
        <Collapse
          isOpen={collapse}
          className="topbar__collapse-content topbar__collapse-content--language"
        >
          {
            languages.map(lang => (
              <button
                type="button"
                key={getUniqueID()}
                className="topbar__language-btn"
                onClick={() => this.changeLanguage(lang)}
              >
                <span className="topbar__language-btn-title">{lang.toUpperCase()}</span>
              </button>
            ))
          }
        </Collapse>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { lang } = state.language;

  return {
    lang,
  };
};

export default connect(mapStateToProps)(TopbarLanguage);
